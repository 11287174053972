import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { css } from "@emotion/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import gsap from "gsap";
import SEO from "../components/Seo/Seo";

const About = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "profile-pic.png" }) {
          childImageSharp {
            fluid(traceSVG: { color: "#ff7601" }) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  );

  return (
    <div className="container ">
      <div className="center-content about">
        <SEO title="About" />
        <h1 className="page-title">ABOUT</h1>
        <Img
          css={css`
            border: 2px solid #e7d5bf;
            border-radius: 50%;
            width: 150px;
            height: 150px;
            margin: 10px 0 30px 0;
          `}
          fluid={data.file.childImageSharp.fluid}
        />

        <p className="page-info">
          Hi! I'm a London-based web developer, mainly working with frontend
          technologies but I also like to dabble with Python backend frameworks
          such as Django and Wagtail. I have also got some PHP experience
          working with Laravel.
        </p>
        <p>
          Having built up solid industry experience on a wide range of projects
          since 2015, from microsites to enterprise-level SaaS applications, I'm
          always looking for new challenges to progress and expand my skills.
          I'm a driven and inquisitive guy with a thorough, systematic approach
          to programming. Problem solving, both independently and as part of a
          team, is what I love most about my job.
        </p>
        <p>Check out my previous work.</p>
        <AniLink fade duration={1} to="/projects/">
          <button
            css={css`
              margin-top: 30px;
            `}
            className="btn"
          >
            <FontAwesomeIcon icon={["fas", "folder-open"]} size="sm" />
            Projects
          </button>
        </AniLink>
      </div>
    </div>
  );
};
export default About;
